<template>
  <div>
 
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Manage Websites !</h5>
      </b-row>         
    </div>
  
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="All Records" active>
          <b-row>
            <b-col sm="12" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
          </b-row>
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="SearchList"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
                <b-button variant="success" @click="Delwebsite(data.item.seq_no)">E</b-button>
                <b-button variant="danger" @click="Delwebsite(data.item.seq_no)">D</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New Record">
          <b-row class="text-center">
            <b-col cols="8 my-4">
            <!-- <b-alert show variant="success"  v-if="MsgSuccess">Record created successfully!</b-alert>
            <b-alert show variant="danger"  v-if="MsgDanger">Error! Some Issue while create a new record!</b-alert>                  -->
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="WebUrl">
                  <b-form-input
                    id="WebUrl"
                    v-model="form.WebUrl"
                    placeholder="Enter Website name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="WebTitle">
                  <b-form-input
                    id="WebTitle"
                    v-model="form.WebTitle"
                    placeholder="Enter Website Title"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="WebCatg">
                  <b-form-select 
                    v-model="selected1" 
                    :options="CatgList">
                  </b-form-select>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="WebType">
                  <b-form-select 
                    v-model="selected2" 
                    :options="TypeList">
                  </b-form-select>
                </b-form-group>

                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Reset</b-button>
              </b-form>        
            </b-col>
          </b-row>          

        </b-tab>
        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Inactive Records">
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="ListNewWebsite"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
                <b-button variant="success" @click="Delwebsite(data.item.seq_no)">E</b-button>
                <b-button variant="danger" @click="Delwebsite(data.item.seq_no)">D</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
    name: "App",
    data() {
      return {
      fields: [{key: 'seq_no',sortable: true}, "web_url", "web_category","web_type","actions"],      
      perPage: 20,
      currentPage: 1,        
        form: {
          WebUrl: '',
          WebTitle: '',
          WebCatg: '',
          WebType: '',
        },
        RespArray: [],
        RespAllWebsite: [],
        RespNewWebsite: [],
        RespArrayIns: [],
        show: true,
        SearchBy: '',
        selected1: 'default',
        selected2: 'default',
        CatgList: [
          { value: 'default', text: 'Please select Website Category' },
          { value: 'Drama', text: 'Drama' },
          { value: 'Movies', text: 'Movies'},
        ],
        TypeList: [
          { value: 'default', text: 'Please select Website Type' },
          { value: 'Promoters', text: 'Promoters' },
          { value: 'Institutions', text: 'Institutions'},
          { value: 'Individual', text: 'Individual'},
        ],             
      }
    },
    mounted()
    {
      this.AdminActiveWebsite()
      this.AdminNewWebsite()
    },    
    methods: {
      onSubmit(event) {
      event.preventDefault()
      this.Inswebsite()
        // alert(JSON.stringify(this.form))

      },
      AdminActiveWebsite(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminActiveWebsite')
        .then((resp)=>{
        this.RespAllWebsite=resp.data.AllWebsite;
      })
      },
      AdminNewWebsite(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminNewWebsite')
        .then((resp)=>{
        this.RespNewWebsite=resp.data.NewWebsite;
      })
      },          
      Inswebsite(){  
        const REQformData = new FormData();
        REQformData.append('web_url', this.form.WebUrl);
        REQformData.append('web_title', this.form.WebTitle);
        REQformData.append('web_category', this.selected1);
        REQformData.append('web_type', this.selected2);                
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=Inswebsite',REQformData)
        .then((resp)=>{
          this.RespArrayIns=resp.data.message;
          alert('Inserted Successfully!!')
          this.AdminActiveWebsite()
          this.ClearForms()
      })
      },
      Delwebsite(seq_no){
       const InsformData = new FormData();
        InsformData.append('seq_no', seq_no);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=Delwebsite', InsformData)
        .then((resp)=>{
        this.RespArray=resp.data.message;
        alert('Deleted Successfully!!')
        this.AdminActiveWebsite();
       }).catch(error => console.log(error));
       },
      ClearForms(){  
        this.form.WebUrl = '';
        this.form.WebTitle = '';
        this.selected1 = 'default';
        this.selected2 = 'default';        
      },                 
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      }
    },
    computed: {
    SearchList: function(){
        return this.RespAllWebsite.filter((RespAllWebsite) => {
        return RespAllWebsite.web_url.toLowerCase().match(this.SearchBy.toLowerCase()) && RespAllWebsite.cde_status == 'P'
      });
    },
    ListNewWebsite: function(){
        return this.RespNewWebsite.filter((RespNewWebsite) => {
        return RespNewWebsite.cde_status != 'P'
      });
    },    
    rows() {
      return this.SearchList.length;
    },    
  }    
  }
</script>

<style scoped>
.admin-main .admin-form-login {
  padding: 40px 200px 80px 50px;    
}
</style>
